.section__title {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: var(--primary-font);
    color: var(--primary-color);
    text-align: center;
    font-size: 30px; 
    font-weight: 400;
    text-transform: uppercase;
    word-spacing: 1px; 
    letter-spacing: 2px; 
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.section__title:after, .section__title:before {
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 30%;
    height: 4px;
    content: "";
    right: 30%; 
    margin: auto;
    background-color: var(--primary-color);
  }
.section__title:before { 
    background-color: var(--secondary-color);
    left: 50%; 
    width: 30%;
}

.yellow {
    color: var(--secondary-color) !important;
}
  

@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {

}

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 800px) {
    .section__title {
        font-size: 25px; 
    }
}

@media screen and (max-width: 600px) {

}

@media screen and (max-width: 400px) {

}