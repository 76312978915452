.success {
    min-height: 100vh;
    background-color: var(--primary-color);
    background-image: url("../../assets/png/desktop_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    color: #ffffff;
    font-family: var(--primary-font);
  }

  .successPage__header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.successPage__header h1 {
    font-size: 2rem;
}

@media screen and (max-width: 600px) {
  .successPage__header h1 {
    font-size: 1.6rem;
}
}

@media screen and (max-width: 400px) {
  .successPage__header h1 {
    font-size: 1.6rem;
}
}