.Title {
    text-align: center;
    margin-left: -800px;
    margin-top: 50px;
  }
  
  .title_name {
    color: rgb(18, 18, 98);
    font-size: 24px;
    vertical-align: middle;
    display: inline-block;
    margin-top: -40px;
    margin-left: 10px;
  }
  
  .title_img {
    display: inline-block;
    width:50px;
    height: 50px;
    margin-top: 50px;
  }
 .sub-title1{
    color: rgb(18, 18, 98);
    margin-top: 20px;
    font-size: 30px;
 }
 .sub-title2{
    color: rgb(18, 18, 98);
    margin-top: 40px;
    font-size: 25px;
 }
 .main-text p{
   margin-top: 50px;
    font-size: 18px;
    text-indent: 1em;
 }
 .sub-title3{
    color: rgb(18, 18, 98);
    margin-top: 30px;
    font-size: 20px;
 }
 .sub-title4{
    color: rgb(18, 18, 98);
    margin-top: 30px;
    font-size: 20px;
 }
 .contact{
    color: rgb(18, 18, 98);
    margin-top: 30px;
    font-size: 20px;
 }
 .sidebar{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 10;
    height: 750px;
    width: 400px; /* Adjust the width as needed */
    object-fit: cover;
 }
 .payments_box{
    position: absolute;
    top: 200px;
    right: 250px;
    bottom: 10;
    color: rgb(12, 12, 14);
    padding: 100px 100px ;
    background:rgb(251, 250, 241);
border-radius: 20px;
 }
.p-text{
    position: absolute;
  top: 50px;
   font-size: 25px;
   color: rgb(18, 18, 98);

 }
 .payment-input {
   display:contents;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  
  .payment-input label {
    font-size: 16px;
    font-weight: bold;
    margin-right: 15px;
    width: 120px;
  }
  
  .payment-input input {
    padding: 5px;
    font-size: 14px;
    margin-bottom: 10px;
    width: 100%; 
  }
  .pay-button {
    position: absolute;
    bottom: 20px; 
    left: 50%; 
    transform: translateX(-50%);
    padding: 10px 20px; 
    font-size: 16px;
    background-color: #2cddb7; 
    color: #060707; 
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .modal{
    font-size: 20px;
  }
  
 
 
  