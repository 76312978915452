.speakers {
  padding: 2rem 0;
}

.speakers_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.speakers_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 2.5rem;
  margin-top: 2rem;
}

.speakerCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  font-family: var(--primary-font);
  color: var(--primary-color);
  width: 300px;
}

.speakerCard__photo {
  height: 350px;
  width: 300px;
  transition: ease 0.5s;
  position: relative;
}

.speakerCard__overlay {
  position: absolute;
  background: rgb(255,205,0);
  background: linear-gradient(90deg, rgba(255,205,0,1) 0%, rgba(17,17,17,1) 100%);
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.speaker_icon_div {
  background-color: var(--primary-color);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.speaker_icon {
  font-size: 1.5rem;
  cursor: pointer;
  box-sizing: content-box;
  color: var(--secondary-color);
}

.speakerCard__photo:hover .speakerCard__overlay {
  opacity: 0.8;
}

.speakerCard__content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;

  display: flex;
  align-items: center;
  justify-content: center;
}

.speakerCard__photo:hover .speakerCard__content {
  opacity: 1;
}

.scp__img {
  height: 100%;
  width: 100%;
  /* object-fit: cover; */
}



.speakerCard h2 {
  margin-top: 1rem;
  font-size: 1.25rem;
  text-align: center;
}

.speakerCard p {
  font-size: 1.05rem;
  text-align: center;
}



@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {
  .speakers_container {
    width: 90%;
  }
}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {

}

@media screen and (max-width: 992px) {
  .speakers_container {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {

}

@media screen and (max-width: 600px) {

}

@media screen and (max-width: 400px) {
  .speakerCard__photo {
    height: 280px;
    width: 220px;  }
}