.about {
    min-height: 100vh;
}

.about__header {
    background-color: var(--primary-color);
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    gap: 5rem;
}

.hero_card {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
    font-family: var(--primary-font);
    color: #ffffff;
}

.hero_card_icon {
    font-size: 1.5rem;
    flex-shrink: 0;
}


.hero_card h5 {
    font-size: 1.5rem;
    font-weight: 400;
}
  
.about__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding-top: 4rem;
}

.about__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 80%;
    margin: auto;
    margin-top: 1rem;
}

.about__section p {
    font-family: var(--primary-font);
    color: var(--primary-color);
    font-size: 1.15rem;
    text-align: center;
}

.a:link,
.hero_card_icon {
    color: #ffffff;
}

.map:hover {
    scale: 1.2;
    color: var(--secondary-color);
}


@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {
    .about__container {
        width: 90%;
    }
}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {
    .hero_card_icon {
        font-size: 1.35rem;
    }
    
    .hero_card h5 {
        font-size: 1.35rem;
    }
}

@media screen and (max-width: 992px) {
    .about__header {
        padding: 2rem;
        gap: 3rem;
    }
    .about__container {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .about__header {
        gap: 2rem;
        flex-direction: column;
        align-items: flex-start;
    }
    .hero_card_icon {
        font-size: 1.15rem;
    }
    
    .hero_card h5 {
        font-size: 1.15rem;
    }
}

@media screen and (max-width: 600px) {

}

@media screen and (max-width: 400px) {

}