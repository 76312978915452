@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-padding: 80px;
    scroll-behavior: smooth;
}
  
/* VARIABLES */
:root {
    --primary-font: 'Rubik', sans-serif;
    /* --secondary-font: 'Work Sans', sans-serif; */
    --bg-color: #fefefe;
    --primary-color: #111111;
    --secondary-color: #FFCD00;
    --text-main: #333333;
}
  