.schedule {
    min-height: 100vh;
    padding: 2rem 0;
}
  
.schedule_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.schedule_section {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}



@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {
    .schedule_container {
        width: 90%;
    }
}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {

}

@media screen and (max-width: 992px) {
    .schedule_container {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .schedule_container {
        padding: 1rem;
    }
}

@media screen and (max-width: 600px) {

}

@media screen and (max-width: 400px) {

}